$color-error-message: #ef6ea8;

#services {
  .services-container {
    .service {
      min-height: 465px;
    }
  }
}

.appt-book {
  .appointment-select {
    .appointments-container {
      .sign-in-container {
        .sign-in-form-container {
          .social-login__email-opt-in {
            margin-bottom: 10px;
            #facebook__email-opt-in-register-panel {
              height: 17px;
            }
            .social-login__opt-in-label {
              margin-top: 0px !important;
              padding-left: 5px;
            }
          }
        }
      }
      .book-appt-container {
        .registration__terms,
        .registration__sms-list,
        .registration__email-list {
          width: 100%;
        }
        .registration__terms-text,
        .registration__sms-list-text,
        .registration__email-list-text {
          padding-left: 5px;
        }
        .mobile-phone-fieldset {
          float: right;
          padding: 0px 0px 0px 1px;
          border: 0;
          .appt-book-mobile-prefix {
            display: none;
          }
          .appt-book-mobile {
            width: 100%;
          }
        }
        .error,
        & .error a {
          color: $color-error-message;
        }
      }
      .confirm-container {
        .appt-book-book-submit {
          font-size: 17px;
        }
      }
    }
    .service {
      .service-head {
        min-height: 120px;
      }
    }
  }
  .service-select {
    .service {
      .service-details {
        min-height: 200px;
      }
    }
  }
  .confirmation {
    .confirmation-container {
      .lesson {
        margin-left: 20px;
      }
      .date-time {
        margin-right: -10px;
      }
    }
  }
}

.appt-book-overlay {
  &.appt-book-datepicker-overlay {
    .overlay-content-container {
      top: 120px;
    }
  }
}

#appointments {
  .appointments-page {
    .appt-book-page-header-content {
      &__title {
        h2 {
          margin-left: 240px;
          margin-top: 10px;
          font-weight: bold;
        }
        h4 {
          font-weight: bold;
          margin-left: 240px;
        }
      }
    }
  }
}

#appointment-cancellation {
  display: block;
}

.page-node-293986 {
  .inner {
    max-width: 560px !important;
    padding-left: 10%;
  }
}
