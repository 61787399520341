.page-spp {
  &.goes_great_with {
    &.spp-extra {
      .module-may-we-suggest {
        border-bottom: 0;
      }
    }
  }
}

.pc_spp_view {
  .spp-pc--atff_module {
    .outer-wrap {
      border-top: 1px solid $color-shade-grey;
    }
  }
}
