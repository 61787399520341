.mpp-grid {
  ul {
    &#mpp-product-grid {
      li {
        &.mpp-box {
          .badge {
            top: 0;
            #{$ldirection}: 0;
          }
          .mpp-product-hover-view {
            .badge {
              top: 10px;
              #{$ldirection}: 10px;
            }
          }
        }
      }
    }
  }
}
