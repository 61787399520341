.wrapper {
  #header {
    .outer-wrap {
      .column.branding {
        width: 20%;
        .left_logo-link {
          display: inline-block;
          padding-#{$rdirection}: 0;
        }
        .right_logo-link {
          @include swap_direction(padding, 0 20px);
          display: inline-block;
          img {
            width: 60px;
          }
        }
      }
      .top-right {
        .block {
          @media screen and (max-device-width: 1024px) {
            @include swap_direction(padding, 0 4px);
          }
          &-template-offer-promo-v1 {
            width: 70%;
            padding-#{$ldirection}: 0;
          }
        }
      }
    }
  }
  .topnav-pane {
    .wrap {
      .avatar {
        .twocol {
          width: auto;
        }
      }
    }
  }
  #gnav_signin {
    .signin_container {
      .pane {
        h2.myaccount-hdr {
          float: none;
          & + p {
            float: none;
          }
        }
      }
    }
  }
  .top-right {
    div:nth-child(3) {
      .event-click {
        .dd-content {
          &.active {
            display: none;
          }
        }
      }
    }
  }
  .gnav-cart {
    .cart-confirm-wrapper {
      .cart-overlay-products {
        .cart-product-name {
          width: 150px;
        }
        .prod-details {
          .right-copy {
            .qty {
              float: #{$ldirection};
              margin-#{$rdirection}: 35px;
            }
            .price {
              width: 70px;
              float: #{$rdirection};
            }
          }
        }
      }
      .cart-overlay-products-heading {
        .right-copy {
          .hright {
            text-align: center;
            padding-#{$rdirection}: 0px;
          }
        }
      }
    }
  }
  #navigation {
    .outer-wrap {
      max-width: 1040px;
    }
    /* !important is added as there is a !important in CL base class */
    .nav-menu {
      .mm-formatter-v1 {
        .mm-menu {
          li {
            a.special {
              color: $color-cl-green !important;
            }
          }
        }
      }
    }
  }
  .outer-wrap {
    &.signup {
      top: 23%;
      #{$ldirection}: 55%;
      height: 40%;
      border: 1px solid $color-grey;
      h2 {
        margin-top: 25px;
        font-family: $base-bold-font-family;
        font-size: 26px;
        text-align: #{$ldirection};
        margin-#{$ldirection}: 20px;
        margin-bottom: 15px;
      }
      .subheading {
        margin-bottom: 15px;
        p {
          text-align: #{$ldirection};
          margin-#{$ldirection}: 20px;
          line-height: 1.5;
        }
      }
      .signup-forms {
        .form-submit-wrap {
          padding-#{$ldirection}: 4px;
        }
      }
    }
    &.signedup {
      h2 {
        margin-top: 85px;
      }
    }
  }
  .flex-viewport {
    .slides {
      .clinique-fit-banner-img {
        display: inline;
        width: 34%;
      }
      .clinique-fit-banner-header {
        font-size: 28px;
        padding-bottom: 25px;
      }
      .cta {
        a.button {
          font-size: 1.2em;
        }
      }
    }
  }
  #quickshop.module-spp-detail {
    .strike-thru-price {
      display: none;
    }
  }
}

#footer {
  .footer-bottom {
    .country-chooser {
      margin-#{$rdirection}: 20px;
      width: auto;
    }
  }
}

.cs-page-section-formatter-v1 {
  p {
    font-size: 13px;
  }
}

#foreground-node {
  &.overlay-container {
    .cs-page-section-formatter-v1 {
      @include swap_direction(padding, 0px 10px);
      @include swap_direction(margin, 20px 15px);
      height: 500px;
      overflow-y: scroll;
    }
  }
}

#registration,
#viewcart,
#shipping,
#review {
  .back-to-top {
    display: none;
  }
}

.section-offers {
  .field-collection-slides {
    .vcn-dots-processed {
      #offer-shipping {
        .top {
          width: 35%;
          margin-top: 195px;
          margin-#{$ldirection}: 120px;
          h3 {
            font-family: $base-thin-font-family;
            line-height: 38px;
          }
          .cta.cta-btn-processed {
            @include swap_direction(padding, 7px 15px 5px 15px);
            font-family: $font-helvetica45-light;
            border-radius: 5px;
            margin-top: 15px;
          }
        }
      }
      #biy_pick {
        .top {
          position: static;
          float: #{$ldirection};
          margin-top: 158px;
          margin-#{$ldirection}: 53%;
          width: 40%;
          .subheading {
            h3 {
              font-size: 45px;
              color: $color-content-blue;
            }
            p {
              line-height: 8px;
              font-size: 20px;
              color: $color-content-blue;
            }
          }
          .cta.cta-btn-processed {
            @include swap_direction(padding, 7px 15px 5px 15px);
            @include swap_direction(margin, 0);
            background: $color-content-blue;
            color: $color-white;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
