$color-content-blue: #41a2ff;
%sidebar-heading {
  margin-bottom: 10px;
  border-top: 1px solid $color-shade-grey;
  padding-top: 20px;
}
%user_button {
  background: $color-white;
  border: 1px solid $color-cl-green-2;
  color: $color-cl-green-2;
  margin-#{$ldirection}: 10px;
}
%checkout_header {
  font-size: 22px;
  text-align: center;
  color: $color-black;
  font-weight: bold;
  margin-bottom: 0;
}
%checkout_sticky_button {
  float: none;
  width: 100%;
  height: 65px;
  position: fixed;
  bottom: 0;
  #{$ldirection}: 0;
  border: 10px solid $color-white;
  font-size: 15px;
  z-index: 99;
}
%sub-heading {
  font-size: 23px;
  color: $color-cl-darker-grey;
}
%continue-checkout_btn {
  position: fixed;
  bottom: 0;
  #{$ldirection}: 0;
  height: 65px;
  z-index: 99;
  border: 10px solid $color-white;
}
